.formu {
  display: block;
  margin: 0 auto 16px auto;
  max-width: 960px; }

.formu .form_group:after,
.formu .form_grid:after {
  display: block;
  content: '';
  clear: both; }

.formu .form_grid {
  margin: 0;
  display: block;
  padding: 0 4px; }

.form_grid label {
  font-weight: bold;
  font-style: italic;
  color: #777;
  margin: 0;
  padding: 4px 0;
  display: block;
  line-height: 1.1em;
  font-size: 1em;
  cursor: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.formu textarea {
  padding: 4px 8px; }

/*		.formu .form_grid .chosen-container{
			float: left;
		}
		
		.formu select{
			padding:0;
		}*/
.formu input[type=password].requit,
.formu input[type=text].requit,
.formu textarea.requit {
  border: solid 1px #C00;
  background-color: #ffd5d5; }

.formu input[type=password].valide,
.formu input[type=text].valide,
.formu textarea.valide {
  border: solid 1px #008e00;
  background-color: #d5ffd5; }

.formu input[type=password][disabled],
.formu input[type=text][disabled],
.formu textarea[disabled] {
  background-color: #EEE; }

.formu input[type=password][readonly],
.formu input[type=text][readonly],
.formu textarea[readonly] {
  background-color: #FFF; }

.formu input[type=submit] {
  float: right;
  /*			margin-top: 32px;
			margin-top: 8px;*/
  margin-bottom: 8px; }

.formu span.requit {
  color: #C00; }

.formu span.requit.legend {
  font-size: 0.8em;
  line-height: 1.1em; }

.formu span.etiq {
  font-style: italic;
  font-size: 0.9em; }

span.cryptogramme {
  display: block;
  width: 160px;
  margin: 0 auto; }

span.cryptogramme a {
  float: left; }

span.cryptogramme img {
  display: inline-block;
  float: none;
  height: auto !important;
  margin: 0;
  max-width: 100% !important; }

#cryptogram {
  background-color: #FFF; }

input[type=text].placeholder {
  color: #999; }

@media only screen and (min-width: 40.063em) {
  .formu .form_grid {
    float: left;
    width: 100%;
    padding: 0 4px; }
  .formu .form_grid1 {
    width: 50%; } }
